import { Link, Toast } from '@infinex/ui/components';
import { FrameInspectIcon } from '@infinex/ui/icons';
import { truncateAddress } from '@/utils/utils';
import PatronNftImage from '../../assets/images/patron_nft.png';

function ClaimToastContent({ amount }: { amount: number }) {
  return (
    <div className="flex items-center gap-x-2">
      <img src={PatronNftImage} alt="Patron NFT" className="size-4" />
      <p className="body-sm-medium">
        {amount}{' '}
        <span className="body-xs-normal text-slate11">
          PATRON NFT{amount > 1 && 's'}
        </span>
      </p>
    </div>
  );
}

function ClaimSuccessToastExtension({ txHash }: { txHash: string }) {
  return (
    <Link href={`https://etherscan.io/tx/${txHash}`} target="_blank">
      <div className="text-slate11 body-xs-normal flex gap-[1px] underline">
        <FrameInspectIcon className="size-4" />
        {truncateAddress(txHash, 6)}
      </div>
    </Link>
  );
}

export function ClaimSuccessToast({
  amount,
  txHash,
}: {
  amount: number;
  txHash: string;
}) {
  return Toast({
    label: 'CLAIM COMPLETED',
    variant: 'onchain',
    duration: 20000,
    content: <ClaimToastContent amount={amount} />,
    extension: <ClaimSuccessToastExtension txHash={txHash} />,
  });
}

export function ClaimFailedToast() {
  return Toast({
    label: 'CLAIM UNSUCCESSFUL',
    variant: 'error',
    duration: 20000,
    content: <p className="body-xs-normal text-slate11">Please try again</p>,
  });
}
