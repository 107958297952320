import { ChainAsset, ChainAssetId } from '@infinex/asset-config';
import { EvmChainKey, SolanaChainKey } from '@infinex/asset-config/src/chains';
import { Connection, PublicKey } from '@solana/web3.js';

import { type EvmClient } from '../types';
import { getEvmTokenDecimals } from './getEvmDecimals';
import { getSolanaDecimals } from './getSolanaDecimals';

export async function getDecimals({
  rpcClients,
  assets,
}: {
  rpcClients: Record<EvmChainKey, EvmClient> &
    Record<SolanaChainKey, Connection>;
  assets: ChainAsset[];
}): Promise<Record<ChainAssetId, number | null>> {
  const mapped = assets.map(async (asset) => {
    if (asset.chain === 'solana') {
      if (asset.address === 'native') {
        return {
          chainAssetId: asset.chainAssetId,
          decimals: 9,
        };
      }
      return {
        ...asset,
        decimals: await getSolanaDecimals({
          connection: rpcClients.solana,
          mint: new PublicKey(asset.address),
        }),
      };
    } else {
      if (asset.address === 'native') {
        return {
          chainAssetId: asset.chainAssetId,
          decimals: 18,
        };
      }
      return {
        ...asset,
        decimals: await getEvmTokenDecimals({
          client: rpcClients[asset.chain],
          tokenContractAddress: asset.address as `0x${string}`,
        }),
      };
    }
  });

  return (await Promise.all(mapped)).reduce(
    (acc, { chainAssetId, decimals }) => {
      acc[chainAssetId as ChainAssetId] = decimals;
      return acc;
    },
    {} as Record<ChainAssetId, number | null>
  );
}
