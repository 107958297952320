import { mergeProps, useObjectRef } from '@react-aria/utils';
import type { Node } from '@react-types/shared';
import { forwardRef, HTMLAttributes, useRef } from 'react';
import {
  type AriaListBoxOptions,
  useListBox,
  useListBoxSection,
  useOption,
} from 'react-aria';
import type { ListState } from 'react-stately';

import { CheckIcon } from '../../icons';
import { type StyleProps, cn } from '../../utils';

// TODO: replace `unknown` with proper type
type ListBoxProps = {
  domProps?: HTMLAttributes<HTMLUListElement>;
  state: ListState<unknown>;
} & AriaListBoxOptions<unknown> &
  StyleProps;

interface SectionProps {
  section: Node<unknown>;
  state: ListState<unknown>;
}

interface OptionProps {
  item: Node<unknown>;
  state: ListState<unknown>;
}

export const BigComboboxListBox = forwardRef<HTMLUListElement, ListBoxProps>(
  function BigComboboxListBox(props, forwardedRef) {
    const { className, domProps, state, style } = props;

    const domRef = useObjectRef<HTMLUListElement>(forwardedRef);
    const { listBoxProps } = useListBox(props, state, domRef);

    return (
      <ul
        {...mergeProps(listBoxProps, domProps)}
        ref={domRef}
        className={cn('w-full flex-1 overflow-y-auto outline-none', className)}
        style={style}
      >
        {[...state.collection].map((item) =>
          item.type === 'section' ? (
            <ListBoxSection key={item.key} section={item} state={state} />
          ) : (
            <Option key={item.key} item={item} state={state} />
          )
        )}
      </ul>
    );
  }
);

function ListBoxSection({ section, state }: SectionProps) {
  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  });

  return (
    <>
      <li {...itemProps} className="pt-2">
        {section.rendered && (
          <span
            {...headingProps}
            className="mx-3 text-xs font-bold uppercase text-gray-500"
          >
            {section.rendered}
          </span>
        )}
        <ul {...groupProps}>
          {[...section.childNodes].map((node) => (
            <Option key={node.key} item={node} state={state} />
          ))}
        </ul>
      </li>
    </>
  );
}

function Option({ item, state }: OptionProps) {
  const ref = useRef<HTMLLIElement>(null);
  const { optionProps, isDisabled, isFocused, isPressed, isSelected } =
    useOption({ key: item.key }, state, ref);

  return (
    <li
      {...optionProps}
      ref={ref}
      data-rac={undefined}
      data-focused={isFocused || undefined}
      data-disabled={isDisabled || undefined}
      data-pressed={isPressed || undefined}
      data-selected={isSelected || undefined}
      className={cn(
        'text-default body-sm-medium flex cursor-pointer items-center gap-x-3 p-3 outline-none transition-colors duration-150',
        'pressed:bg-neutralHover',
        'data-[focused=true]:bg-canvasInverted/5' // not sure why `focus:*` doesn't work
      )}
    >
      {item.rendered}
      {isSelected && (
        <CheckIcon aria-hidden="true" className="text-accent ms-auto size-5" />
      )}
    </li>
  );
}
