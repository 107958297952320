import { createIcon } from '../createIcon';

export const AirdropIcon = createIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14.347 2.365c5.24 1.404 8.534 6.488 7.626 11.732v.001l-.001.002-.011.004-8.671 4.264-.774 2.89a1.04 1.04 0 0 1-1.274.735l-4.02-1.077a1.04 1.04 0 0 1-.735-1.274l.775-2.892-5.36-8a1.223 1.223 0 0 1-.025-.034l.001-.002v-.002c1.835-4.995 7.23-7.75 12.469-6.347Zm1.817 10.35c.37.366 1.173 1.082 2.13 1.453l-4.75 2.336 2.62-3.79Zm-8.566-2.267.37 4.553-2.934-4.38a5.305 5.305 0 0 0 2.564-.173Zm6.616 2.343c-.65 0-1.497-.094-2.559-.378-1.065-.286-1.845-.63-2.41-.954l.295 3.617 2.61.7 2.064-2.985Zm6.335.033c-.507.071-1.035.079-1.457-.034-.45-.12-.937-.421-1.36-.764a6.34 6.34 0 0 1-.593-.546l-.018-.019-.007-.008-.002-.002-.35-.397c.359-2.372.268-4.467-.2-6.014 2.608 1.704 4.157 4.661 3.987 7.784Zm-5.376-1.707c-.073.023-.214.06-.428.085-.477.057-1.349.062-2.686-.296-1.337-.359-2.09-.799-2.474-1.086a2.4 2.4 0 0 1-.329-.289c1.016-2.707 2.356-4.53 3.494-5.297.531-.359.932-.37 1.183-.302.252.07.596.29.875.873.594 1.241.836 3.48.365 6.312ZM7.95 8.585c.87-2.187 1.981-3.921 3.142-5.011-3.128.173-5.963 1.979-7.37 4.796.32.248.756.504 1.304.651a3.71 3.71 0 0 0 2.22-.105l.008-.003.696-.328Z"
  />,
  'AirdropIcon'
);
