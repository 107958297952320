import {
  AccountAssetBalance,
  AssetBalance,
  ChainAsset,
  ChainKey,
  EvmChainKey,
  SolanaChainKey,
} from '@infinex/asset-config';
import { Connection, PublicKey } from '@solana/web3.js';
import { type Address } from 'viem';

import { EvmClient } from '../types';
import { getEvmNativeTokenBalance } from './getEvmNativeTokenBalance';
import {
  getEvmAssetBalances,
  getEvmAssetBalancesForManyAccounts,
  getEvmTokenBalance,
} from './getEvmTokenBalance';
import { getSolanaNativeBalance } from './getSolanaNativeTokenBalance';
import { getSplAssetBalances } from './getSolanaTokenBalance';

export async function getBalances({
  evmAccountAddress,
  solanaAccountAddress,
  rpcClients,
  chainAssets,
}: {
  evmAccountAddress?: Address;
  solanaAccountAddress?: string | PublicKey;
  rpcClients: Record<EvmChainKey, EvmClient> &
    Record<SolanaChainKey, Connection>;
  chainAssets: ChainAsset[];
}): Promise<AssetBalance[]> {
  const grouped = chainAssets.reduce(
    (acc, ca) => {
      acc[ca.chain] = [...(acc[ca.chain] || []), ca];
      return acc;
    },
    {} as Record<ChainKey, ChainAsset[]>
  );

  const balancePromises = Object.entries(grouped).map(
    async ([chain, assets]) => {
      if (chain === 'solana' && solanaAccountAddress) {
        return getSolanaAssetBalances(
          rpcClients.solana,
          chainAssets,
          solanaAccountAddress as string
        );
      } else if (chain !== 'solana' && evmAccountAddress) {
        return getEvmChainAssetBalances({
          chain: chain as EvmChainKey,
          rpcClients,
          evmAccountAddress,
          chainAssets,
        });
      } else {
        return mapToEmptyState(
          evmAccountAddress || solanaAccountAddress?.toString() || '',
          assets
        );
      }
    }
  );

  return await Promise.all(balancePromises).then((res) => res.flat());
}

async function getEvmChainAssetBalances({
  chain,
  rpcClients,
  evmAccountAddress,
  chainAssets,
}: {
  chain: EvmChainKey;
  rpcClients: Record<EvmChainKey, EvmClient>;
  evmAccountAddress: Address;
  chainAssets: ChainAsset[];
}): Promise<AssetBalance[]> {
  // ERC-20s
  const evmChainAssets = chainAssets.filter(
    (a) => a.chain === chain && a.address !== 'native'
  );

  // Multi-call may not exist on all RPC clients, but should exist on all ours.
  // see more here https://www.multicall3.com/deployments
  try {
    const [evmAssetBalances, nativeAssetBalance] = await Promise.all([
      getEvmAssetBalances({
        client: rpcClients[chain],
        accountAddress: evmAccountAddress,
        evmChainAssets,
      }),
      (async (): Promise<AssetBalance | null> => {
        const nativeAsset = chainAssets.find(
          (a) => a.chain === chain && a.address === 'native'
        );

        if (nativeAsset) {
          const { balance, failed } = await getEvmNativeTokenBalance({
            client: rpcClients[chain],
            accountAddress: evmAccountAddress,
          });
          return {
            ...nativeAsset,
            walletAddress: evmAccountAddress,
            address: nativeAsset.address as string,
            balance,
            failed,
          };
        }
        return null;
      })(),
    ]);

    // Combined
    if (nativeAssetBalance) {
      evmAssetBalances.push(nativeAssetBalance);
    }

    return evmAssetBalances;
  } catch (e) {
    console.error(e);
    // Fall back to individual calls
    return Promise.all(
      evmChainAssets.map(async (a) => {
        const { balance, failed, nftData } =
          a.address === 'native'
            ? await getEvmNativeTokenBalance({
                client: rpcClients[chain],
                accountAddress: evmAccountAddress,
              })
            : await getEvmTokenBalance({
                client: rpcClients[chain],
                tokenContractAddress: a.address as Address,
                accountAddress: evmAccountAddress,
                type: a.type,
              });

        return {
          ...getMetadataFields(a),
          address: a.address as Address,
          walletAddress: evmAccountAddress,
          balance,
          nftData,
          failed,
        };
      })
    );
  }
}

/**
 * Gets balances of a single asset for a list of account addresses.
 */
export async function getEvmAssetBalanceForManyAccounts<
  Chain extends EvmChainKey,
>({
  chain,
  rpcClients,
  evmAccountAddresses,
  chainAsset,
  blockNumber,
}: {
  chain: Chain;
  rpcClients: Record<Chain, EvmClient>;
  evmAccountAddresses: Address[];
  chainAsset: ChainAsset;
  blockNumber?: bigint;
}): Promise<AccountAssetBalance[]> {
  // Multi-call may not exist on all RPC clients, but should exist on all ours.
  // see more here https://www.multicall3.com/deployments
  try {
    const evmAssetBalances = await getEvmAssetBalancesForManyAccounts({
      client: rpcClients[chain],
      accountAddresses: evmAccountAddresses,
      evmChainAsset: chainAsset,
      blockNumber,
    });

    return evmAssetBalances;
  } catch (e) {
    console.error(e);
    // Fall back to individual calls
    return Promise.all(
      evmAccountAddresses.map(async (acc) => {
        const { balance, failed } = await getEvmTokenBalance({
          client: rpcClients[chain],
          tokenContractAddress: chainAsset.address as Address,
          accountAddress: acc,
          type: chainAsset.type,
        });

        return {
          ...getMetadataFields(chainAsset),
          assetAddress: chainAsset.address as Address,
          walletAddress: acc,
          balance,
          failed,
        };
      })
    );
  }
}

async function getSolanaAssetBalances(
  connection: Connection,
  solanaChainAssets: ChainAsset[],
  solanaAccountAddress: string
): Promise<AssetBalance[]> {
  // SPL asset balances, and native balance
  const [splAssetBalances, nativeBalance] = await Promise.all([
    getSplAssetBalances({
      connection,
      accountAddress: new PublicKey(solanaAccountAddress),
      splSupportedChainAssets: solanaChainAssets.filter(
        (a) => a.address !== 'native'
      ),
    }),
    (async () => {
      const nativeAsset = solanaChainAssets.find(
        (a) => a.chainAssetId === 'solana_sol'
      )!;

      if (!nativeAsset) {
        return null;
      }

      return {
        ...nativeAsset,
        address: nativeAsset.address.toString(),
        walletAddress: solanaAccountAddress.toString(),
        balance: await getSolanaNativeBalance({
          connection,
          accountAddress: new PublicKey(solanaAccountAddress),
        }),
      };
    })(),
  ]);

  // Combined
  return [...splAssetBalances, ...(nativeBalance ? [nativeBalance] : [])];
}

function emptyState(asset: ChainAsset) {
  return {
    address: asset.address.toString(),
    balance: 0n,
  };
}

function getMetadataFields(asset: ChainAsset) {
  const {
    chain,
    chainAssetId,
    assetId,
    name,
    symbol,
    decimals,
    type,
    isManualControlled,
  } = asset;
  return {
    chain,
    chainAssetId,
    assetId,
    name,
    symbol,
    decimals,
    type,
    isManualControlled,
  };
}

// Removes coingeckoId, adds balance and address
function mapToEmptyState(accountAddress: string, assets: ChainAsset[]) {
  return assets.map((a) => {
    return {
      walletAddress: accountAddress.toString(),
      ...getMetadataFields(a),
      ...emptyState(a),
    };
  });
}
