import { Outlet } from 'react-router-dom';

import { Footer } from './components/footer';
import { Header } from './components/header';

export const DefaultLayout = () => {
  return (
    <div className="bg-canvas relative flex min-h-screen flex-col justify-start overflow-x-hidden">
      <Header />

      <div className="flex w-full flex-1 justify-center sm:py-16">
        <Outlet />
      </div>

      <Footer />
    </div>
  );
};
