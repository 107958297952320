import { MultiProgressBarRadial } from '@infinex/ui/components';

import { formatNumber } from '@/utils/utils';

export const TotalPatronsProgress = ({
  totalPatrons,
  claimedPatrons,
  claimablePatrons,
  lockedPatrons,
  inPlatform,
}: {
  totalPatrons: number;
  claimedPatrons: number;
  claimablePatrons: number;
  lockedPatrons: number;
  inPlatform?: boolean;
}) => {
  const showEmptyState = claimedPatrons === 0 && claimablePatrons === 0;

  return (
    <div className="relative flex flex-1 flex-grow items-center justify-center">
      <MultiProgressBarRadial
        size="3xl"
        portions={
          showEmptyState
            ? [{ value: 100, color: '#3C4147' }]
            : [
                ...(claimedPatrons > 0
                  ? [
                      {
                        value: claimedPatrons,
                        color: inPlatform ? '#70FF93' : '#FC4913',
                      },
                    ]
                  : []),
                ...(claimablePatrons > 0
                  ? [{ value: claimablePatrons, color: '#70FF93' }]
                  : []),
                ...(lockedPatrons > 0
                  ? [{ value: lockedPatrons, color: '#3C4147' }]
                  : []),
              ]
        }
      >
        <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center gap-4 text-center">
          <span className="title-5xl-semibold">
            {formatNumber(totalPatrons)}
          </span>
          <span className="title-xs-medium text-secondary leading-none">
            Total Patrons
          </span>
        </div>
      </MultiProgressBarRadial>
    </div>
  );
};
