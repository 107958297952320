import { type ForwardedRef, forwardRef } from 'react';
import {
  type PopoverProps as RACPopoverProps,
  Popover as RACPopover,
} from 'react-aria-components';

import { type StyleProps, cn } from '../../utils';

type PopoverProps = Omit<RACPopoverProps, 'className' | 'style'> & StyleProps;

export const Popover = forwardRef(function Popover(
  props: PopoverProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { className, ...otherProps } = props;

  return (
    <RACPopover
      {...otherProps}
      ref={ref}
      className={cn(
        'bg-shark4 rounded border shadow-lg',
        // common animations
        'entering:animate-in entering:fade-in entering:ease-out',
        'exiting:animate-out exiting:fade-out',
        // placement animations
        'data-[placement=bottom]:entering:slide-in-from-top-2',
        'data-[placement=top]:entering:slide-in-from-bottom-2',
        'data-[placement=left]:entering:slide-in-from-right-2',
        'data-[placement=right]:entering:slide-in-from-left-2',
        className
      )}
    />
  );
});
