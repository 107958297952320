import { AppKitNetwork } from '@reown/appkit/networks';
import {
  type SIWESession,
  type SIWEVerifyMessageArgs,
  type SIWECreateMessageArgs,
  createSIWEConfig,
  formatMessage,
} from '@reown/appkit-siwe';
import { Client, client } from '@/providers/client';
import { UserStore } from '@/stores';

// call the server to get a nonce
const getNonce = async (): Promise<string> => {
  const nonce = await client.patronClaim.getNonce();

  return nonce;
};

// call the server to verify the message
const verifyMessage = async ({ message, signature }: SIWEVerifyMessageArgs) => {
  try {
    const result = await client.patronClaim.verifySiweMessage({
      siweMessage: message,
      signature,
    });

    return result;
  } catch (error) {
    return false;
  }
};

// call the server to get the session
const getSession = async (client: Client, userStore: UserStore) => {
  const session = await userStore.fetchSession(client);

  return session == undefined ? null : (session as SIWESession);
};

// call the server to sign out
const signOut = async (
  client: Client,
  userStore: UserStore
): Promise<boolean> => {
  return await userStore.logout(client);
};

export const createSIWE = (
  chains: [AppKitNetwork, ...AppKitNetwork[]],
  client: Client,
  userStore: UserStore,
  onSignInCallback: () => void
) => {
  return createSIWEConfig({
    signOutOnAccountChange: true,
    signOutOnNetworkChange: true,
    getMessageParams: async () => ({
      domain: window.location.host,
      uri: window.location.origin,
      chains: chains.map((chain: AppKitNetwork) =>
        parseInt(chain.id.toString())
      ),
      statement: 'Please sign with your account',
    }),
    createMessage: ({ address, ...args }: SIWECreateMessageArgs) => {
      return formatMessage(args, address);
    },
    getNonce,
    getSession: () => getSession(client, userStore),
    verifyMessage,
    signOut: () => signOut(client, userStore),
    onSignIn: () => {
      onSignInCallback();
    },
  });
};
