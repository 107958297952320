import { cva } from 'class-variance-authority';
import {
  type ForwardedRef,
  type ReactNode,
  createContext,
  forwardRef,
  useContext,
} from 'react';
import {
  type InputProps as RACInputProps,
  type GroupProps,
  type LabelProps as RACLabelProps,
  type FieldErrorProps as FieldErrorProps,
  FieldError,
  FieldErrorContext,
  Group,
  Input as RACInput,
  Label,
  TextContext as RACTextContext,
} from 'react-aria-components';

import { type LiteralVariantProps, type StyleProps, cn } from '../../utils';
import { Text } from '../content';

// Field context
// -----------------------------------------------------------------------------

type FieldContextValue = {
  size: InputVariant['size'];
  isDisabled?: boolean;
  isInvalid?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
};

export const FieldContext = createContext<FieldContextValue>({
  isDisabled: false,
  isInvalid: false,
  isReadOnly: false,
  isRequired: false,
  size: 'medium',
});

// Field label
// -----------------------------------------------------------------------------

export type FieldLabelProps = RACLabelProps & { extension?: string };

/**
 * @private A thin wrapper around react-aria-components' `Label` component, for
 * specific styling and behaviour.
 */
export const FieldLabel = forwardRef(function FieldLabel(
  props: FieldLabelProps,
  ref: ForwardedRef<HTMLLabelElement>
) {
  const { children, className, extension, ...otherProps } = props;
  const fieldCtx = useContext(FieldContext);

  // unlikely but better to warn
  if (extension && !children) {
    console.warn('The `labelExtension` should accompany a `label`.');
  }

  return (
    <Label
      ref={ref}
      className={cn(
        'text-secondary cursor-default leading-none',
        {
          'body-sm-medium':
            fieldCtx.size === 'small' || fieldCtx.size === 'medium',
          'title-sm-medium': fieldCtx.size === 'large',
          'text-disabled': fieldCtx.isDisabled,
        },
        className
      )}
      {...otherProps}
    >
      {children}

      {fieldCtx.isRequired && (
        <span
          aria-hidden
          style={{
            display: 'inline-block',
            fontSize: '1.125em',
            marginBottom: '-0.125em',
            marginTop: '-0.125em',
          }}
        >
          {NNBSP}*
        </span>
      )}

      {extension ? <span className="font-light"> {extension}</span> : null}
    </Label>
  );
});

const NNBSP = '\u202F';

// Help text
// -----------------------------------------------------------------------------

export type HelpTextProps = {
  /**
   * The description explains requirements or adds supplementary context for how
   * to successfully interact with a component.
   */
  description?: ReactNode;
} & Pick<FieldErrorProps, 'children'>;

/**
 * @private Help text provides either an informative description or an error
 * message that gives more context about what a user needs to input.
 */
export const HelpText = forwardRef(function HelpText(
  props: HelpTextProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const fieldCtx = useContext(FieldContext);
  const errorCtx = useContext(FieldErrorContext) || {};
  const textSize = {
    'body-xs-normal': fieldCtx.size === 'small' || fieldCtx.size === 'medium',
    'body-sm-normal': fieldCtx.size === 'large',
  };

  if (!errorCtx.isInvalid && props.description) {
    return (
      <Text slot="description" className={cn('text-subtle', textSize)}>
        {props.description}
      </Text>
    );
  }

  return (
    <FieldError ref={ref} className={cn('text-critical', textSize)}>
      {props.children}
    </FieldError>
  );
});

// Input
// -----------------------------------------------------------------------------

const inputVariants = cva(
  'w-full bg-none outline-none placeholder:text-subtle text-default caret-interactiveActive disabled:text-disabled',
  {
    variants: {
      // fix for mobile safari zoom behaviour:
      // - "touch" prefix targets `hover: none + pointer: coarse` by media query
      // - increase font to 16px (min allowed)
      size: {
        small: 'body-sm-normal px-3 touch:body-base-normal',
        medium: 'body-sm-normal px-3 touch:body-base-normal',
        large: 'body-base-normal px-4',
      },
    },
  }
);

type InputVariant = LiteralVariantProps<typeof inputVariants>;
export type InputProps = Omit<RACInputProps, 'className' | 'style'> &
  StyleProps;

/**
 * @private A thin wrapper around react-aria-components' `Input` component, for
 * specific styling and behaviour.
 */
export const Input = forwardRef(function Input(
  props: InputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { className, ...otherProps } = props;
  const { size } = useContext(FieldContext);
  return (
    <RACInput
      {...otherProps}
      ref={ref}
      className={cn(inputVariants({ size }), className)}
    />
  );
});

// Input group
// -----------------------------------------------------------------------------

export type InputGroupProps = Omit<GroupProps, 'className' | 'style'> &
  StyleProps;

/**
 * @private A container for the input element, adornments and related controls.
 * It provides consistent layout and styling.
 */
export const InputGroup = forwardRef(function InputGroup(
  props: InputGroupProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { children, className } = props;

  const fieldCtx = useContext(FieldContext);

  return (
    // Clear "description" and "errorMessage" slots, so adornments containing
    // `Text` components don't throw.
    <RACTextContext.Provider value={undefined}>
      <Group
        isDisabled={fieldCtx.isDisabled}
        isInvalid={fieldCtx.isInvalid}
        ref={ref}
        // Forward focus to the input element when press on a non-interactive
        // child. Mimic browser behaviour:
        // - via mouse, focus immediately
        // - via touch, make sure it's not a scroll attempt
        onPointerDown={(e) => {
          if (
            e.pointerType === 'mouse' &&
            !(e.target as Element).closest('button,input,textarea')
          ) {
            e.preventDefault();
            e.currentTarget.querySelector('input')?.focus();
          }
        }}
        onPointerUp={(e) => {
          if (
            e.pointerType !== 'mouse' &&
            !(e.target as Element).closest('button,input,textarea')
          ) {
            e.preventDefault();
            e.currentTarget.querySelector('input')?.focus();
          }
        }}
        className={cn(
          'relative z-0 flex w-full cursor-text',
          'bg-shark4 rounded border border-[transparent] outline-none outline-offset-[-1px] transition',
          'hover:bg-shark5 hover:border-interactiveHover',
          'focus-within:bg-shark4 focus-within:outline-accent',
          'invalid:border-interactiveCritical invalid:hover:border-interactiveCritical',
          'disabled:bg-shark4/50 disabled:text-disabled disabled:cursor-default',
          {
            'h-8': fieldCtx.size === 'small',
            'h-10': fieldCtx.size === 'medium',
            'h-12': fieldCtx.size === 'large',
          },
          className
        )}
      >
        {children}
      </Group>
    </RACTextContext.Provider>
  );
});
