import React, { useId } from 'react';
import { ProgressBar } from 'react-aria-components';
import { cn } from '../../utils';

interface ProgressPortion {
  value: number;
  color: string | [string, string];
}

interface MultiProgressBarRadialProps {
  portions: ProgressPortion[];
  strokeWidth?: number;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
  children?: React.ReactNode;
  className?: string;
  isPulsing?: boolean;
  bgStrokeColor?: string;
}

export const MultiProgressBarRadial = ({
  portions,
  strokeWidth = 2,
  size = 'md',
  children,
  className,
}: MultiProgressBarRadialProps) => {
  const sizeClassMap: Record<
    NonNullable<MultiProgressBarRadialProps['size']>,
    string
  > = {
    xs: 'size-8',
    sm: 'size-12',
    md: 'size-16',
    lg: 'size-24',
    xl: 'size-40',
    '2xl': 'size-48',
    '3xl': 'size-64',
    '4xl': 'size-80',
    '5xl': 'size-96',
  };

  const sizeClass = sizeClassMap[size] || sizeClassMap['md'];

  // SVG Id
  const randomId = useId();

  // Increase the viewBox size to accommodate the glow
  const viewBoxSize = 48;
  const center = viewBoxSize / 2;
  const r = center - strokeWidth - 2; // Subtract 2 to account for the glow effect
  const circumference = 2 * r * Math.PI;

  return (
    <div className={cn(`relative ${sizeClass}`, className)}>
      <ProgressBar aria-label="Progress" value={100}>
        {() => {
          const totalValue = portions.reduce((sum, p) => sum + p.value, 0);
          const normalizedPortions = portions.map((p) => ({
            ...p,
            percentage: (p.value / totalValue) * 100,
          }));

          const GAP_SIZE = circumference * 0.02;
          const totalGapLength = GAP_SIZE * portions.length;
          const availableLength = circumference - totalGapLength;

          let currentOffset = 0;

          return (
            <svg
              id={`progress-bar-radial-${randomId}`}
              width="100%"
              height="100%"
              viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
              fill="none"
            >
              <defs>
                {normalizedPortions.map(
                  (portion, index) =>
                    Array.isArray(portion.color) && (
                      <linearGradient
                        key={`gradient-${index}`}
                        id={`gradient-${index}-${randomId}`}
                        gradientTransform={`rotate(30 ${center} ${center})`}
                      >
                        <stop offset="0%" stopColor={portion.color[0]} />
                        <stop offset="45%" stopColor={portion.color[0]} />
                        <stop offset="55%" stopColor={portion.color[1]} />
                        <stop offset="100%" stopColor={portion.color[1]} />
                      </linearGradient>
                    )
                )}
              </defs>
              {normalizedPortions.map((portion, index) => {
                const segmentLength =
                  (portion.percentage / 100) * availableLength;
                currentOffset += GAP_SIZE / 2;

                const dashArray = `${segmentLength} ${circumference - segmentLength}`;
                const dashOffset = -currentOffset;

                currentOffset += segmentLength + GAP_SIZE / 2;

                return (
                  <circle
                    key={index}
                    cx={center}
                    cy={center}
                    r={r}
                    stroke={
                      Array.isArray(portion.color)
                        ? `url(#gradient-${index}-${randomId})`
                        : portion.color
                    }
                    strokeWidth={strokeWidth}
                    strokeLinecap="round"
                    strokeDasharray={dashArray}
                    strokeDashoffset={dashOffset}
                    transform={`rotate(-90 ${center} ${center})`}
                  />
                );
              })}
            </svg>
          );
        }}
      </ProgressBar>
      {children}
    </div>
  );
};
