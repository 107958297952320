import { LockClockIcon, SproutIcon } from '@infinex/ui/icons';
import { cn } from '@infinex/ui/utils';
import {
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
} from 'react-aria-components';

import { formatNumber } from '@/utils/utils';

import { VestingItem } from '.';

export default function VestingScheduleTable({
  vestingItems,
}: {
  vestingItems: VestingItem[];
}) {
  return (
    <Table
      aria-label="Vesting Schedule Log"
      className="w-full border-collapse overflow-hidden whitespace-nowrap [&_td:not(:last-child)]:pr-10 [&_th:not(:last-child)]:pr-10"
    >
      <TableHeader className="text-subtle font-condensed text-xs font-medium uppercase tracking-[1.1px]">
        <Column isRowHeader className="w-1/6 py-3 text-left uppercase">
          Period
        </Column>
        <Column className="w-1/6 py-3 text-left">Start Date</Column>
        <Column className="w-1/6 py-3 text-left">End Date</Column>
        <Column className="w-1/6 py-3 text-left">Total Patrons</Column>
        <Column className="w-1/6 py-3 text-right">Vested</Column>
        <Column className="w-1/6 py-3 text-right">Status</Column>
      </TableHeader>
      <TableBody
        items={vestingItems}
        className="text-default divide-subtle title-sm-medium divide-y text-sm"
      >
        {(vestingItem) => (
          <Row className="text-slate11 h-12 text-sm">
            <Cell className="align-middle">{vestingItem.period}</Cell>
            <Cell className="align-middle">
              {vestingItem.startDate.toLocaleDateString('en-US', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
              })}
            </Cell>
            <Cell className="align-middle">
              {vestingItem.endDate.toLocaleDateString('en-US', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
              })}
            </Cell>
            <Cell
              className={cn(
                'text-slate12 text-right align-middle',
                vestingItem.totalPatrons === 0 && 'text-subtle'
              )}
            >
              {formatNumber(vestingItem.totalPatrons)}
            </Cell>
            <Cell
              className={cn(
                'text-slate12 text-right align-middle',
                vestingItem.vested === 0 && 'text-subtle'
              )}
            >
              {formatNumber(vestingItem.vested)}
            </Cell>
            <Cell
              className={cn(
                'text-right align-middle',
                vestingItem.status === 'Locked' && 'text-subtle'
              )}
            >
              <div className="flex items-center justify-end gap-x-2">
                {vestingItem.totalPatrons - vestingItem.vested < 1 ? (
                  '–'
                ) : (
                  <>
                    {vestingItem.status}
                    {vestingItem.status === 'Vesting' ? (
                      <SproutIcon className="text-positiveSubtle size-4" />
                    ) : vestingItem.status === 'Locked' ? (
                      <LockClockIcon className="text-ruby11 size-4" />
                    ) : null}
                  </>
                )}
              </div>
            </Cell>
          </Row>
        )}
      </TableBody>
    </Table>
  );
}
