import {
  type PropsWithChildren,
  type ReactNode,
  forwardRef,
  useContext,
} from 'react';
import {
  type TextFieldProps as RACTextFieldProps,
  Provider,
  TextField as RACTextField,
} from 'react-aria-components';

import { IconContext } from '../../icons';
import { type StyleProps, cn } from '../../utils';
import { TextContext } from '../content';
import {
  FieldContext,
  FieldLabel,
  HelpText,
  Input,
  InputGroup,
} from '../field';

export type TextFieldProps = {
  /** The label of the field. For "visually hidden" labels, use the `aria-label` attribute. */
  label?: string;
  /** The label extension is displayed after the label, in a less prominent font. */
  labelExtension?: string;
  /**
   * Hint text is displayed below the label to give extra context or instruction
   * about what a user should input in the field.
   */
  hint?: string;
  /**
   * Avoid placeholders, which are inaccessible and disappear during input.
   * Prefer `hint` text to offer essential information.
   */
  placeholder?: string;
  /**
   * The size of the text field's input element.
   * @default medium
   */
  size?: 'small' | 'medium' | 'large';
  /** Element to display before the input. */
  startElement?: ReactNode;
  /** Element to display after the input. */
  endElement?: ReactNode;
} & Omit<RACTextFieldProps, 'children' | 'isInvalid' | 'className' | 'style'> &
  StyleProps;

// ^ RAC omissions:
// - children, className, and style: render props not supported
// - isInvalid: we don't (yet) support realtime validation

/**
 * Text fields allow users to input text with a keyboard. Use when the expected
 * input is a single line of text.
 */
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function TextField(props, forwardedRef) {
    const {
      className,
      endElement,
      hint,
      label,
      labelExtension,
      size = 'medium',
      startElement,
      ...otherProps
    } = props;

    return (
      <RACTextField
        className={cn('flex w-full flex-col items-start gap-2', className)}
        {...otherProps}
      >
        {(renderProps) => (
          <Provider values={[[FieldContext, { size, ...renderProps }]]}>
            {label || labelExtension ? (
              <FieldLabel extension={labelExtension}>{label}</FieldLabel>
            ) : null}

            <InputGroup role="presentation">
              {startElement && (
                <Adornment placement="start">{startElement}</Adornment>
              )}

              <Input ref={forwardedRef} />

              {endElement && (
                <Adornment placement="end">{endElement}</Adornment>
              )}
            </InputGroup>

            <HelpText description={hint}>
              {({ validationErrors }) => validationErrors.join(' ')}
            </HelpText>
          </Provider>
        )}
      </RACTextField>
    );
  }
);

const Adornment = (
  props: PropsWithChildren<{ placement: 'start' | 'end' }>
) => {
  const { children, placement } = props;

  const { isDisabled, size } = useContext(FieldContext);
  const isLarge = size === 'large';

  const common = [
    'text-secondary select-none self-center shrink-0',
    {
      '-me-1': placement === 'start',
      '-ms-1': placement === 'end',
      'ms-2': placement === 'start' && size === 'small',
      'me-2': placement === 'end' && size === 'small',
      'ms-3': placement === 'start' && size === 'medium',
      'me-3': placement === 'end' && size === 'medium',
      'ms-4': placement === 'start' && size === 'large',
      'me-4': placement === 'end' && size === 'large',
      'text-disabled': isDisabled,
    },
  ];

  return (
    <Provider
      values={[
        [
          IconContext,
          {
            className: cn(common, {
              'size-5': !isLarge,
              'size-6': isLarge,
            }),
          },
        ],
        [
          TextContext,
          {
            className: cn(common, {
              'body-sm-normal': !isLarge,
              'body-base-normal': isLarge,
            }),
          },
        ],
      ]}
    >
      {children}
    </Provider>
  );
};
