import { DefaultLayout } from '@/components/layouts/Default';
import { LogInPage } from '@/pages/log-in';
import { HomePage } from '@/pages/home';
import { LoadingPage } from '@/pages/loading-page';
import { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-aria-components';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { NotFound } from '@/pages/not-found';
import { ProtectedRoute } from './ProtectedRoute';

export enum AppRoutes {
  ROOT = '/',
  NOT_FOUND = '/not-found',

  // AUTHENTICATION & ONBOARDING
  LOG_IN = '/log-in',

  // AUTHENTICATED
  HOME = '/home',
}

/* 
	Router Switch
	- Use this component to wrap all routes
	Protected Routes 
	- Wrap all routes that are only accesible when logged in in a protected route
	Background State 
*/
export const RouterSwitch = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <RouterProvider navigate={navigate}>
      <Routes location={location}>
        <Route path={AppRoutes.ROOT} element={<DefaultLayout />}>
          {/* Auth & onboarding */}
          <Route path={AppRoutes.LOG_IN} element={<LogInPage />} />
          <Route path={AppRoutes.NOT_FOUND} element={<NotFound />} />

          {/* Authenticated routes */}
          <Route
            element={
              <ProtectedRoute
                path={AppRoutes.LOG_IN}
                needLoggedInState={true}
              />
            }
          >
            <Route index element={<Navigate to={AppRoutes.HOME} replace />} />

            {/* HOMEPAGE */}
            <Route path={AppRoutes.HOME} element={<HomePage />} />
          </Route>
        </Route>
      </Routes>
    </RouterProvider>
  );
};

export const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingPage />}>
        <ScrollToTop />
        <RouterSwitch />
      </Suspense>
    </BrowserRouter>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
