import { NavLink } from 'react-router-dom';

import { ReactComponent as DiscordLogo } from '@/assets/images/discord.svg';
import { ReactComponent as XLogo } from '@/assets/images/x.svg';
import { TitleLink } from '@infinex/ui/components';

export const Footer = () => {
  return (
    <div className="inset-x-0 bottom-0 z-10 flex w-full items-center justify-between px-4 py-2 sm:fixed lg:px-6">
      <div className="flex items-center gap-x-4">
        <TitleLink
          prominence="subtle"
          isExternalLink
          href="https://proposals.infinex.xyz/"
        >
          Governance
        </TitleLink>
      </div>

      <div className="flex items-center gap-x-4">
        <NavLink
          to="https://discord.gg/infinex"
          className="text-subtle hover:text-accent transition-colors"
          target="_blank"
        >
          <DiscordLogo />
        </NavLink>
        <NavLink
          to="https://twitter.com/infinex_app"
          className="text-subtle hover:text-accent transition-colors"
          target="_blank"
        >
          <XLogo />
        </NavLink>
      </div>
    </div>
  );
};
