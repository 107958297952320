import { useSuspenseQuery } from '@tanstack/react-query';
import { useAuthContext } from '@/providers/auth';
import { useClientContext } from '@/providers/client';
import React from 'react';
import { Navigate, Outlet, PathRouteProps } from 'react-router-dom';
import { useUserStore } from '@/stores';

interface ProtectedRouteProps extends PathRouteProps {
  needLoggedInState?: boolean;
  path: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  needLoggedInState,
  path,
}) => {
  const { isLoggedIn } = useAuthContext();
  const { client } = useClientContext();
  const userStore = useUserStore();

  useSuspenseQuery({
    queryKey: ['fetchSession'],
    queryFn: async () =>
      // We don't want to trigger the suspense error boundary so we can wrap the
      // user fetch in a promise that always resolves.
      new Promise((resolve) => {
        userStore
          .fetchSession(client!)
          .catch((e) => {
            console.error('Do not have permissions to access this page.');
          })
          .finally(() => {
            resolve(true);
          });
      }),
  });

  if (
    (needLoggedInState && isLoggedIn) ||
    (!needLoggedInState && !isLoggedIn)
  ) {
    return <Outlet />;
  } else {
    return <Navigate to={path} replace />;
  }
};
