import { filterDOMProps } from '@react-aria/utils';
import type { AriaLabelingProps, DOMProps } from '@react-types/shared';
import {
  type ForwardedRef,
  type ReactNode,
  createContext,
  forwardRef,
} from 'react';
import {
  type ContextValue,
  type SlotProps,
  useContextProps,
  useSlottedContext,
} from 'react-aria-components';

import { type StyleProps, cn } from '../utils';

/**
 * TODO: Improve with a `size` prop when design team has defined sizes.
 */
type IconProps = {
  /**
   * Whether the element should be hidden, within a slotted component.
   */
  isHidden?: boolean;
} & AriaLabelingProps &
  DOMProps &
  SlotProps &
  StyleProps;

export type IconContextValue = {
  /**
   * Wrap the element with some custom behaviour, within a slotted component.
   */
  render?: (icon: ReactNode) => ReactNode;
} & IconProps;

export const IconContext = createContext<
  ContextValue<IconContextValue, SVGSVGElement>
>({});

export const createIcon = (path: ReactNode, name: string) => {
  const Icon = forwardRef(
    (props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
      [props, ref] = useContextProps(props, ref, IconContext);
      const ctx = useSlottedContext(IconContext);

      const { className, style, ...otherProps } = props;
      const hasAriaLabel = props['aria-label'] || props['aria-labelledby'];

      if (props.isHidden) {
        return null;
      }

      const svg = (
        <svg
          {...filterDOMProps(otherProps, { labelable: true })}
          ref={ref}
          aria-hidden={hasAriaLabel ? undefined : true}
          fill="currentColor"
          focusable="false"
          role="img"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className={cn('size-6 flex-none', className)}
          style={style}
          // @ts-expect-error: arbitrary attribute on SVG
          slot={props.slot || 'icon'}
        >
          {path}
        </svg>
      );

      if (ctx?.render) {
        return ctx.render(svg);
      }

      return svg;
    }
  );

  // For nicer output in React Dev Tools
  Icon.displayName = name;

  return Icon;
};
