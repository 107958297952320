import { filterDOMProps, useObjectRef } from '@react-aria/utils';
import type { DOMProps } from '@react-types/shared';
import { type ReactNode, type ForwardedRef, forwardRef } from 'react';
import {
  type AriaPopoverProps,
  DismissButton,
  Overlay,
  usePopover,
} from 'react-aria';
import { type DialogProps, Modal, Dialog } from 'react-aria-components';
import type { OverlayTriggerState } from 'react-stately';

import { type StyleProps, cn, useIsSmallScreen } from '../../utils';

type TrayConfig = {
  dialogProps?: DialogProps;
  fixedHeight?: boolean;
};
export type BigComboboxPopoverProps = Omit<AriaPopoverProps, 'popoverRef'> & {
  children: ReactNode;
  /**
   * When provided, the popover will render as a tray on small screens.
   */
  trayConfig?: TrayConfig;
  state: OverlayTriggerState;
} & DOMProps &
  StyleProps;

export const BigComboboxPopover = forwardRef(function BigComboboxPopover(
  props: BigComboboxPopoverProps,
  forwardedRef: ForwardedRef<HTMLDivElement>
) {
  const {
    children,
    className,
    trayConfig,
    isNonModal,
    state,
    style,
    ...otherProps
  } = props;

  const popoverRef = useObjectRef<HTMLDivElement>(forwardedRef);
  const isSmallScreen = useIsSmallScreen();
  const { popoverProps, underlayProps } = usePopover(
    { ...otherProps, popoverRef, isNonModal },
    state
  );

  if (isSmallScreen && trayConfig) {
    return (
      <Modal
        {...filterDOMProps(otherProps)}
        isDismissable
        isOpen={state.isOpen}
        className={cn(
          'z-dialog bg-blanket fixed left-0 top-0 w-screen',
          'flex flex-col justify-end',
          // animations
          'entering:animate-in entering:fade-in',
          'exiting:animate-out exiting:fade-out'
        )}
        style={{
          height: 'var(--visual-viewport-height)',
        }}
      >
        {(renderProps) => {
          return (
            <Dialog
              {...trayConfig.dialogProps}
              data-rac=""
              data-entering={renderProps.isEntering}
              data-exiting={renderProps.isExiting}
              className={cn(
                'bg-shark3 overflow-hidden rounded-t-md shadow-lg outline-none backdrop-blur-lg backdrop-filter',
                // animations
                'entering:animate-in entering:slide-in-from-bottom entering:ease-out entering:duration-200'
              )}
              style={{
                height: trayConfig.fixedHeight
                  ? 'calc(var(--visual-viewport-height) - 44px)'
                  : undefined,
                // always define max-height so it can be inherited
                maxHeight: 'calc(var(--visual-viewport-height) - 44px)',
              }}
            >
              {children}
            </Dialog>
          );
        }}
      </Modal>
    );
  }

  return (
    state.isOpen && (
      <Overlay>
        {!isNonModal && <div {...underlayProps} className="fixed inset-0" />}
        <div
          {...popoverProps}
          ref={popoverRef}
          className={cn(
            'bg-shark3 my-2 flex flex-col overflow-hidden rounded-md border shadow-lg outline-none backdrop-blur-lg backdrop-filter',
            className
          )}
          style={{ ...popoverProps.style, ...style }}
        >
          {!isNonModal && <DismissButton onDismiss={state.close} />}

          {children}

          <DismissButton onDismiss={state.close} />
        </div>
      </Overlay>
    )
  );
});
