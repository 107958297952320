import { EvmTokenBalance } from '@infinex/asset-config';
import { getBalance } from 'viem/actions';

import { EvmClient } from '../types';

export async function getEvmNativeTokenBalance<TClient extends EvmClient>({
  client,
  accountAddress,
}: {
  client: TClient;
  accountAddress: `0x${string}`;
}): Promise<EvmTokenBalance> {
  try {
    const nativeBalance = await getBalance(client, {
      address: accountAddress,
    });

    return { balance: nativeBalance };
  } catch (e) {
    console.error(e);
    return { balance: 0n, failed: true };
  }
}
